<template>
  <v-dialog width="unset" v-model="dialog">
    <v-card light width="500px" clas="justify-center" rounded raised>
      <v-toolbar flat>
        <v-card-title class="px-2">Update Insurance Info</v-card-title>
        <v-spacer />
        <v-btn icon @click="cleanInput()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-select
            v-model="fertilityCompany"
            :items="fertilityCompanyOptions"
            :label="fertilityCompany"
            placeholder="Fertility Specific Insurance"
            :rules="inputRule"
            validate-on-blur
          />
          <v-select
            v-model="primaryInsurance"
            :items="primaryInsuranceOptions"
            :label="primaryInsurance"
            placeholder="Primary Insurance"
            :rules="inputRule"
            validate-on-blur
          />
          <v-row>
            <v-col>
              <v-text-field
                label="Policy Number or Member ID"
                v-model="policyNumber"
                :rules="inputRule"
                validate-on-blur
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Group Number"
                v-model="groupNumber"
                :rules="inputRule"
                validate-on-blur
              />
            </v-col>
          </v-row>
          <v-text-field
            label="Policy Holder Name"
            v-model="policyHolderName"
            :rules="inputRule"
            validate-on-blur
          />

          <v-text-field
            label="Policy Holder SSN"
            v-model="policyHolderSSN"
            :rules="inputRule"
            validate-on-blur
          />

          <div style="padding: 20px; margin-bottom: 20px">
            <v-row>
              <p class="policy-holder-dob">Policy Holder DOB</p>
            </v-row>
            <v-row>
              <date-picker
                v-model="policyHolderDOB"
                format="MM-DD-YYYY"
                :confirm="true"
              />
            </v-row>
          </div>
          <div style="padding: 20px">
            <v-row>
              <div class="attach row" elevation="0">
                <div class="card-title-attach">Front Image:</div>
                <v-file-input
                  chips
                  prepend-icon="mdi-camera"
                  v-model="frontImage"
                  accept="image/*"
                  placeholder="Please upload front insurance image"
                />
              </div>
            </v-row>
            <v-row>
              <div class="attach row" elevation="0">
                <div class="card-title-attach">Back Image:</div>
                <v-file-input
                  chips
                  prepend-icon="mdi-camera"
                  v-model="backImage"
                  accept="image/*"
                  placeholder="Please upload back insurance image"
                />
              </div>
            </v-row>
          </div>

          <v-row class="mt-2">
            <v-col md="6">
              <v-btn
                class="mx-0"
                block
                color="#1F6070"
                dark
                @click="cleanInput()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn class="mx-0" block color="#1F6070" dark @click="submit">
                Update Insurance
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { auth, api } from '../sharedPlugin';
import axios from 'axios';

export default {
  components: { DatePicker },
  data() {
    return {
      primaryInsurance: '',
      fertilityCompany: '',
      policyHolderName: '',
      policyHolderDOB: '',
      policyNumber: '',
      groupNumber: '',
      policyHolderSSN: '',
      frontImage: null,
      backImage: null,
      frontImageBase64: null,
      backImageBase64: null,
      dialog: false,
      valid: false,
      inputRule: [(v) => !!v || 'Input Required'],
      accountRule: [
        (v) =>
          (v && (v.length == 15 || v.length == 16)) ||
          'Card number should be 15-16 characters long'
      ],
      decimalRule: [
        (v) =>
          !isNaN(Number.parseFloat(v)) || 'Input must be a valid decimal value'
      ],
      cvvRule: [
        (v) =>
          (v && (v.length == 3 || v.length == 4)) || 'Must be 3 or 4 characters'
      ],
      onlyNumsRule: [(v) => (v && /^\d+$/.test(v)) || 'Only numbers allowed'],
      dateOfBirthRule: [
        (v) => !!v || 'Date of birth is required',
        (v) => new Date(v) < Date.now() || 'The date of birth must be valid'
      ],
      fertilityCompanyOptions: [
        'None',
        'Progyny',
        'Carrot',
        'Win Fertility',
        'Stork Club',
        'Maven',
        'Other'
      ],
      primaryInsuranceOptions: [
        'No Insurance',
        'Aetna',
        'Anthem',
        'Blue Cross Blue Shield',
        'Cigna',
        'Optum',
        'United Healthcare',
        'UMR',
        'Other'
      ]
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    async getPatientInsurance() {
      try {
        const response = await api.PatientInsurances.getPatientInsurance(
          this.user.patientId
        );

        console.log(response);

        if (response) {
          (this.fertilityCompany = !response[0].fertilityCompany
            ? ''
            : response[0].fertilityCompany),
            (this.primaryInsurance = !response[0].primaryInsurance
              ? ''
              : response[0].primaryInsurance),
            (this.policyNumber = !response[0].policyNumber
              ? ''
              : response[0].policyNumber),
            (this.groupNumber = !response[0].groupNumber
              ? ''
              : response[0].groupNumber),
            (this.policyHolderName = !response[0].policyHolderName
              ? ''
              : response[0].policyHolderName),
            (this.policyHolderSSN = !response[0].policyHolderSSN
              ? ''
              : response[0].policyHolderSSN),
            (this.policyHolderDOB = !response[0].policyHolderDOB
              ? ''
              : Date(response[0].policyHolderDOB));
        }
      } catch (error) {
        this.throwError(error);
      }
    },
    throwError(error) {
      // Handle error
      console.error('Error:', error);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.handleFileChange();

        try {
          await api.PatientInsurances.updatePatientInsurance(
            this.user.patientId,
            this.primaryInsurance,
            this.fertilityCompany,
            this.policyHolderName,
            `${(this.policyHolderDOB.getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${this.policyHolderDOB
              .getDate()
              .toString()
              .padStart(2, '0')}-${this.policyHolderDOB.getFullYear()}`,
            this.groupNumber,
            this.policyNumber,
            this.policyHolderSSN,
            this.frontImageBase64,
            'front.png',
            this.backImageBase64,
            'back.png'
          );

          this.cleanInput();
        } catch (error) {
          // eslint-disable-next-line
          console.error('Error updating patient insurance:', error);
        }
      }
    },

    async handleFileChange() {
      // Convert the front image to base64
      const fileFront = this.frontImage;
      if (fileFront instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          this.frontImageBase64 = reader.result.split(',')[1];
        };
        await new Promise((resolve) => {
          reader.onloadend = resolve;
          reader.readAsDataURL(fileFront);
        });
      }

      // Convert the back image to base64
      const fileBack = this.backImage;
      if (fileBack instanceof Blob) {
        const reader2 = new FileReader();
        reader2.onload = () => {
          this.backImageBase64 = reader2.result.split(',')[1];
        };
        await new Promise((resolve) => {
          reader2.onloadend = resolve;
          reader2.readAsDataURL(fileBack);
        });
      }
    },
    base64ToImage(base64String, name) {
      const img = new Image();
      img.src = base64String;
      img.alt = name;
      img.name = name;
      return img;
    },
    onChanged(val) {
      this.$emit('input', { ...this.value, ...val });
    },
    async show() {
      this.$emit('input', {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: '',
        amount: '',
        address: '',
        lastname: '',
        firstname: ''
      });
      console.log('SHOOOOOOWWWWWW');
      await this.getPatientInsurance();
      this.dialog = true;
    },
    cleanInput() {
      this.$emit('input', {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: '',
        amount: '',
        address: '',
        lastname: '',
        firstname: ''
      });
      this.dialog = false;
      this.$emit('close');
      window.location.reload();
    }
  }
};
</script>

<style scoped>
.v-btn {
  margin: 5px;
}

.policy-holder-dob {
  font-size: 11px;
  margin-bottom: 0px !important;
}
.payment-announcement {
  border: 2px solid #1f6070; /* Add a blue border */
  padding: 10px; /* Add padding between the border and text */
  border-radius: 15px; /* Add border-radius to create circular corners */
  text-align: center;
}

.announcement-text {
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold; /* Make text more bold */
  color: #333; /* Change text color as needed */
  margin: 0; /* Remove default margin (optional) */
}
</style>

<style lang="scss">
.attach {
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  height: 45px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid gray;
  align-content: center;
  align-items: center;

  .card-title-attach {
    padding: 4px;
    padding-left: 15px;
    margin-right: 5px;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }

  // .border-radius-16-attach {
  //   height: 38px;
  // }
}

.v-file-input > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.v-file-input > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
