<template>
  <v-container>
    <v-card :loading="loading">
      <div class="header-left">
        <!-- First Card Title with "header-label" class -->
        <v-card-title class="header-label no-padding">
          Make a payment
        </v-card-title>

        <!-- Second Card Title with "small-header-label" class -->
        <v-card-title class="small-header-label no-padding">
          Select amount
        </v-card-title>
      </div>
      <div>
        <div class="payment-options-container">
          <div
            class="payment-options"
            @click="togglePdbSelected"
            v-if="!isOutsidePhysicianAndToggledOff"
          >
            <img
              :src="getPdbImageSource"
              alt="radio-btn"
              :class="{ 'greyed-out': pdbCheck === 1 }"
            />
            <h2 :class="{ 'greyed-out': pdbCheck === 1 }">Past Due Balance:</h2>
            <h2 :class="{ 'greyed-out': pdbCheck === 1 }">
              {{ this.loadedPastDueBalance }}
            </h2>
            <button v-popover:pdbTooltip class="tool-tip">ⓘ</button>
            <popover name="pdbTooltip" class="tooltip-right">
              Unpaid balance carried over from previous billing periods
            </popover>
          </div>
          <div class="payment-options" @click="toggleCbSelected">
            <img :src="getCbImageSource" alt="radio-btn" />
            <h2 class="space-after-text">Current Bill:</h2>
            <h2>{{ this.loadedCurrentBill }}</h2>
            <button v-popover:cbTooltip class="tool-tip">ⓘ</button>
            <popover name="cbTooltip" class="tooltip-right">
              Amount owed on current statement and/or payment for upcoming
              treatment cycle
            </popover>
          </div>
        </div>
      </div>
      <div class="button-container">
        <v-btn
          :class="nothingSelected ? 'bottom-btn-nothing' : 'bottom-btn'"
          @click="openModal()"
          class="continue-btn"
        >
          CONTINUE
        </v-btn>
      </div>
      <PayrocHostedPage
        ref="payrocHostedPage"
        v-if="payrocPay"
        :paymentAmount="paymentAmt"
        @onReceiveMessage="payrocHandle"
      />
      <CardInfo
        v-else
        ref="cardInfo"
        v-model="card"
        :dialog="dialog"
        :amt="paymentAmt"
        :submitting="submitting"
        :rectangleHealth="rectangleHealthPay"
        :user="user"
        @close="dialog = !dialog"
        @submit="pay"
        showBilling
        showAmount
        @onTokenPay="handleTokenPay"
        @onSubmitting="(event) => (this.submitting = event)"
      />
      <div v-if="!isOutsidePhysicianAndToggledOff">
        <!-- Title -->
        <div @click="togglePendingPayments" class="title-container">
          <h2 class="title">Pending Payments</h2>
          <div class="chevron-container">
            <img
              v-if="pp_expanded"
              src="../assets/Chevron_Down.png"
              alt="Down"
              class="chevron"
            />
            <img
              v-else
              src="../assets/Chevron_Up.png"
              alt="Up"
              class="chevron"
            />
          </div>
        </div>

        <!-- Body (conditionally rendered) -->
        <v-card :loading="loading" v-if="pp_expanded">
          <div class="payment-section">
            <!-- Title -->
            <div class="header-row">
              <div class="section-title date-title">Date</div>
              <div class="section-title amount-title">Amount</div>
            </div>
            <!-- Data (conditionally rendered) -->
            <div
              v-for="(item, index) in pendingPaymentsData"
              :key="index"
              class="data-row"
            >
              <div class="date-column">{{ item.Payment_Date }}</div>
              <div class="amount-column">{{ item.Pending_Payments }}</div>
            </div>
            <h2>Please allow 1-2 business days for processing.</h2>
          </div>
        </v-card>
      </div>

      <div v-if="!isOutsidePhysicianAndToggledOff">
        <!-- Title -->
        <div @click="togglePaymentHistory()" class="title-container">
          <h2 class="title">Payment History</h2>

          <div class="chevron-container">
            <img
              v-if="ph_expanded"
              src="../assets/Chevron_Down.png"
              alt="Down"
              class="chevron"
            />
            <img
              v-else
              src="../assets/Chevron_Up.png"
              alt="Up"
              class="chevron"
            />
          </div>
        </div>

        <!-- Body (conditionally rendered) -->
        <v-card :loading="loading" v-if="ph_expanded">
          <div class="payment-section">
            <!-- Title -->
            <div class="header-row">
              <div class="section-title date-title">Date</div>
              <div class="section-title amount-title">Amount</div>
            </div>

            <!-- Data (conditionally rendered) -->
            <div
              v-for="(item, index) in paymentHistoryData"
              :key="index"
              class="data-row"
            >
              <div class="date-column">{{ item.Payment_Date }}</div>
              <div class="amount-column">{{ item.Payment }}</div>
            </div>
          </div>
        </v-card>
      </div>
    </v-card>
    <Notify
      v-if="notification.show"
      :width="containerWidth"
      :type="notification.type"
      no-title
      @close="notification.show = false"
      @consent="notification.show = false"
      :message="notification.message"
    />
  </v-container>
</template>

<script>
import CardInfo from '../components/CardInfo.vue';
import PayrocHostedPage from '../components/payroc/PayrocHostedPage';
import Vue from 'vue';
import Popover from 'vue-js-popover';
import { api, auth, messages, partner } from '../sharedPlugin';
import Notify from '@/components/global/Notify';
const Swal = require('sweetalert2');
Vue.use(Popover);

export default {
  components: { Notify, CardInfo, PayrocHostedPage },
  data() {
    return {
      loading: false,
      submitting: false,
      dialog: false,
      dialogItemizedLedger: false,
      loadedPastDueBalance: 0,
      loadedCurrentBill: 0,
      pp_expanded: false,
      ph_expanded: false,
      pendingPaymentsData: null,
      paymentHistoryData: null,
      pdbSelected: false,
      cbSelected: false,
      nothingSelected: true,
      paymentAmt: 0,
      pdbCheck: 0,
      greyOutChecker: 2,
      outsidePhys: false,
      //total will be pulled from all bills in the api
      finances: {
        balance: 0,
        Company_ID: null,
        Company: '',
        Credit: null,
        Message: '',
        total: '0.00'
      },
      card: {
        account: null,
        amount: '',
        cvv: null,
        expiry: '',
        zipcode: null,
        cardtype: null,
        firstname: '',
        lastname: '',
        address: ''
      },
      showFinancialLedger: false,
      financialLedgerGroup: null,
      createFinancialLedgerDocument: false,
      balance: 0,
      showCryoBalanceCard: false,
      notification: {
        show: false,
        message: null,
        type: 'error'
      },
      //tokenPay for rectangle health API
      tokenPay: null,
      rectangleHealthPay: null,
      paymentTypes: {
        PPS: 'pps',
        RECTANGLE_HEALTH: 'rectangleHealth',
        PAYROC: 'payroc'
      },
      paymentType: 'pps',
      payrocPay: false,
      payrocRes: {}
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner']),
    total() {
      return this.finances.balance ? this.finances.balance.toFixed(2) : 0;
    },
    isEnabled() {
      return this.finances.Company_ID !== null;
    },
    icon() {
      return this.pp_expanded
        ? 'packages/connect-web/src/assets/Chevron_Up.png'
        : 'packages/connect-web/src/assets/Chevron_Down.png';
    },
    icon2() {
      return this.ph_expanded
        ? 'packages/connect-web/src/assets/Chevron_Up.png'
        : 'packages/connect-web/src/assets/Chevron_Down.png';
    },
    getPdbImageSource() {
      return this.pdbSelected && !this.pdbCheck
        ? require('../assets/selected_radio_button.png')
        : require('../assets/unselected_radio_button.png');
    },
    getCbImageSource() {
      return this.cbSelected
        ? require('../assets/selected_radio_button.png')
        : require('../assets/unselected_radio_button.png');
    },
    greyOut() {
      if (!this.pdbCheck) {
        return true;
      } else {
        return false;
      }
    },
    isOutsidePhysicianAndToggledOff() {
      return this.outsidePhys && this.user.clinicId === 6;
    }
  },
  mounted() {
    this.getRectangleHealthClients();
    this.getPayrocClients();

    if (this.user?.features?.cryo) {
      this.showCryoBalanceCard = true;
      this.getBalance();
    }

    this.checkOutsidePhys();
    this.loadBalance();
    this.getPastDueBalance();
    this.getCurrentBill();
    this.getPendingPayments();
    this.getPaymentHistory();
    this.get72hrPastDueBalance();

    this.showFinancialLedger =
      this.user?.clinicSettings?.show_financial_ledger || false;
    this.financialLedgerGroup =
      this.user?.clinicSettings?.financial_ledger_group || null;
    this.createFinancialLedgerDocument =
      this.user?.clinicSettings?.create_financial_ledger_document || false;
  },
  methods: {
    ...messages.mapMethods([
      'setMessageTo',
      'pullMessageGroupsAsync',
      'createConversationAsync'
    ]),
    openModal() {
      switch (true) {
        case this.payrocPay:
          this.$refs.payrocHostedPage.submit();
          break;
        default:
          this.$refs.cardInfo.show();
          break;
      }
    },
    // toggle pending payments section
    togglePendingPayments() {
      this.pp_expanded = !this.pp_expanded;
    },
    togglePaymentHistory() {
      this.ph_expanded = !this.ph_expanded;
    },
    async checkOutsidePhys() {
      this.loading = true;
      await api.Finance.checkOutsidePhys(
        this.user.patientEhrId,
        this.user.clinicId
      ).then((res) => {
        this.outsidePhys = res.data;
        console.log(
          'Is this patients provider an Outside Physician: ' + this.outsidePhys
        );
        this.loading = false;
      });
    },
    togglePdbSelected() {
      if (this.pdbSelected == false && !this.pdbCheck) {
        this.cbSelected = false;
        this.pdbSelected = true;
        this.greyOutChecker = 0;
      } else {
        this.pdbSelected = false;
        this.cbSelected = true;
        this.greyOutChecker = 1;
      }
      this.nothingSelected = false;
      this.updatePaymentAmt();
    },
    toggleCbSelected() {
      if (this.cbSelected == false) {
        this.cbSelected = true;
        this.pdbSelected = false;
        this.greyOutChecker = 1;
      } else if (!this.pdbCheck) {
        this.pdbSelected = true;
        this.cbSelected = false;
        this.greyOutChecker = 0;
      }
      this.nothingSelected = false;
      this.updatePaymentAmt();
    },
    updatePaymentAmt() {
      if (this.pdbSelected && !this.pdbCheck) {
        this.paymentAmt = this.loadedPastDueBalance;
      }
      if (this.cbSelected) {
        this.paymentAmt = parseFloat(
          this.loadedCurrentBill.replace(/[$,]/g, '')
        );
      }
      console.log('Here is what you are going to pay: ' + this.paymentAmt);
      if (this.paymentAmt <= 0) this.nothingSelected = true;
      console.log('Grey Out Checker: ' + this.greyOutChecker);
    },
    async get72hrPastDueBalance() {
      this.loading = true;
      return await api.Finance.get72hrPastDueBalance(this.user.patientId).then(
        (res) => {
          this.pdbCheck = res.data[0].PastDuePaidRecently;
          console.log(
            'Here is what the 72 hour check produces: ' + this.pdbCheck
          );
          this.loading = false;
        }
      );
    },
    async getPastDueBalance() {
      this.loading = true;
      api.Finance.getPastDueBalance(this.user.patientEhrId, this.user.clinicId)
        .then((res) => {
          // Format the loadedPastDueBalance as a currency string
          this.loadedPastDueBalance = res.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          });
        })
        .catch((err) => {
          console.error(err);
          // this.loadedPastDueBalance = '$0.00'; // Handle the error, setting the default value to $0.00
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCurrentBill() {
      this.loading = true;
      api.Finance.getCurrentBill(this.user.patientId)
        .then((res) => {
          this.loadedCurrentBill = res.data;

          console.log(
            'The current bill api call has been called: ' +
              this.loadedCurrentBill
          );
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error('err loading current bill amt: ' + err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getPendingPayments() {
      // console.log(this.user);
      this.loading = true;
      return await api.Finance.getPendingPayments(
        //108388, 24)
        this.user.patientEhrId,
        this.user.clinicId
      )

        .then((res) => {
          this.pendingPaymentsData = res.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error(
            'An error occurred while fetching pending payments: ',
            error
          );
          this.loading = false;
        });
      // this.loading = false;
    },
    async getPaymentHistory() {
      this.loading = true;
      return await api.Finance.getPaymentHistory(
        this.user.patientEhrId,
        this.user.clinicId
      )
        .then((res) => {
          this.paymentHistoryData = res.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error(
            'An error occurred while fetching payment history: ',
            error
          );
          this.loading = false;
        });
      // this.loading = false;
    },

    loadBalance() {
      this.loading = true;
      api.Finance.getBalance()
        .then((res) => (this.finances = res))
        .finally(() => (this.loading = false));
    },
    pay() {
      this.submitting = true;
      // Conditionally set the total based on if this this past due balance payment or current bill payment
      const totalAmount = this.pdbSelected
        ? parseFloat(this.card.amount.replace('$', '').replace(',', ''))
        : this.card.amount;

      // Make the API call with the updated totalAmount
      api.Finance.pay({
        ...this.finances,
        ...this.card,
        ...this.payrocRes,
        total: totalAmount,
        client: this.user.clinicId,
        patientid: this.user.userId,
        paidType: this.greyOutChecker,
        authorizationTokenPay: this.tokenPay,
        paymentType: this.paymentType
      })
        .then((res) => {
          this.dialog = false;

          if (res && res.AppCode) {
            if (this.payrocPay) this.$refs.payrocHostedPage.close();
            console.log(
              'This is the type I am paying with: ' + this.greyOutChecker
            );
            Swal.fire({
              title: 'Payment Successful!',
              text: 'Request sent successfully!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            });
          } else {
            if (this.payrocPay) this.$refs.payrocHostedPage.close();
            Swal.fire({
              title: 'Payment failed!',
              text: res && res.Message ? res.Message : 'Payment failed!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            });
          }
        })
        .catch((err) => {
          this.$store.commit('showSnackbar', {
            message: err,
            color: 'red'
          });
        })
        .finally(() => {
          this.submitting = false;
          switch (true) {
            case this.payrocPay:
              window.location.reload();
              break;
            default:
              this.$refs.cardInfo.cleanIput();
              break;
          }
          this.loadBalance();
        });
    },
    async sendMessage() {
      try {
        this.dialogItemizedLedger = false;

        /*let messageTo = {
          subject: 'Itemized Ledger',
          message:
            'I would like to request an itemized ledger, please send it as soon as possible.',
          botShown: true,
          groupId: this.financialLedgerGroup
        };*/

        let username = this.user?.fullName || 'You';

        if (
          this.getPartner &&
          this.getPartner.ownerName &&
          this.getPartner.name
        ) {
          let firtNameOwner = this.getPartner.ownerName.split(' ');
          let firtNamePartner = this.getPartner.name.split(' ');
          username = firtNameOwner[0] + ' on behalf of ' + firtNamePartner[0];
        }

        if (this.user?.clinicSettings?.create_financial_ledger_document) {
          api.Documents.createFinancialLedgerDocument()
            .then((res) => console.log(res))
            .finally(() => {});
        }

        const data = {
          subject: 'Itemized Ledger',
          message:
            'I would like to request an itemized ledger, please send it as soon as possible.',
          botShown: true,
          groupId: this.financialLedgerGroup,
          patientId: this.user.userId,
          patientRequestId: null,
          attachmentName: null,
          attachmentFile: null,
          createdByName: username
        };
        await api.Conversations.addConversation(data)
          .then((res) => {
            this.notification.message = `Your request for an itemized ledger has been received.`;
            this.notification.type = 'success';
            this.notification.show = true;
          })
          .catch((err) => {
            console.log("ERROR: couldn't create conversation", err);

            this.notification.message = `Your request for an itemized ledger failed!`;
            this.notification.type = 'error';
            this.notification.show = true;
          })
          .finally(() => (this.isLoading = false));
      } catch (error) {
        console.log(error);
      }
    },
    formatPrice(value) {
      return value ? value.toFixed(2) : 0.0;
    },
    getBalance() {
      this.loading = true;
      api.Cryo.getCryoBalance()
        .then((res) => {
          if (res.length > 0) {
            let tempBalance = res[0]?.balance;
            this.balance = tempBalance ? tempBalance?.toFixed(2) : '0.00'; //this.formatPrice(tempBalance);
          }
        })
        .finally(() => (this.loading = false));
    },
    toCryoLink() {
      this.$router.push({ path: '/cryo' });
    },
    handleTokenPay(data) {
      this.tokenPay = data;
      this.paymentType = this.paymentTypes.RECTANGLE_HEALTH;
    },
    async getRectangleHealthClients() {
      try {
        const response = await api.RectangleHealth.getRectangleHealthClients();
        if (response.length && 'hasError' in response[0]) {
          throw Error('The clients has too many process payments active');
        }
        this.rectangleHealthPay = response.some(
          (e) => e.id == this.user.clinicId && e.eivf
        );
      } catch (error) {
        const msg = `Error getting rectangle health clients: ${error.message}`;
        console.error(msg);
        await Swal.fire({
          title: 'Internal Server Error',
          text: error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ok'
        });
        await this.$router.push({ path: '/' });
      }
    },
    async getPayrocClients() {
      try {
        const response = await api.Payroc.getPayrocClients();
        if (response.length && 'hasError' in response[0]) {
          throw Error('The clients has too many process payments active');
        }
        this.payrocPay = response.some(
          (e) => e.id === this.user.clinicId && e.eivf
        );
        if (this.payrocPay) {
          this.paymentType = this.paymentTypes.PAYROC;
        }
      } catch (error) {
        const msg = `Error getting payroc clients: ${error.message}`;
        console.error(msg);
        await Swal.fire({
          title: 'Internal Server Error',
          text: error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ok'
        });
        await this.$router.push({ path: '/' });
      }
    },
    async payrocHandle({ payrocRes }) {
      this.payrocRes = { ...this.payrocRes, ...payrocRes };
      this.pay();
    }
  }
};
</script>

<style lang="scss">
.small-header-label {
  color: var(--black, #000);
  font-family: 'SF Pro Text';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  padding-top: 0px !important;
}
.header-label {
  color: var(--black, #000);
  font-family: 'SF Pro Display';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 0px;
}

.no-padding {
  padding: 0 !important;
}

.header-left {
  align-items: left;
  padding: 10px 0px 10px 10px;
}

.payment-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center titles horizontally */
}

.section-title {
  font-weight: bold;
  text-align: center;
}

.data-row {
  display: flex;
  justify-content: space-between; /* Columns on opposite sides */
  padding: 5px; /* Add padding for spacing */
}

.title-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Align items to the start and end */
  padding: 15px 80px 15px 80px;
}

.title {
  margin-right: 8px; /* Add spacing between title and chevron */
}

.chevron-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.chevron {
  width: 16px; /* Adjust the width of the chevron icon */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.payment-section {
  display: flex;
  justify-content: space-between; /* Space elements apart */
  //align-items: center;
}

.header-row {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.section-title {
  flex: 1;
  font-weight: bold;
  text-align: center;
  padding: 8px; /* Add some padding for spacing */
  color: #1f6070;
}

.date-title {
  /* Add specific styles for the "Date" title */
  // color: #1f6070;
  text-align: left; /* Align text to the left */
  margin-right: 250px;
}

.amount-title {
  /* Add specific styles for the "Amount" title */
  // color: #1f6070;
  text-align: right; /* Align text to the right */
}

.data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space elements apart */
  // align-items: center;
  padding: 8px; /* Add some padding for spacing */
}

.date-column {
  flex: 1;
  text-align: left; /* Align text to the left */
  margin-right: 250px;
}

.amount-column {
  flex: 1;
  text-align: right; /* Align text to the right */
}

.payment-options-container {
  display: grid;
  grid-template-rows: repeat(2, auto); /* Create two rows for the components */
  gap: 20px; /* Adjust the gap as needed */
  justify-content: center; /* Center the children horizontally */
}

.payment-options {
  display: flex;
  flex: 1 !important;
  text-align: center;
  flex-direction: row;
  align-items: center; /* Vertically center align the content in each row */
  cursor: pointer; /* Add pointer cursor to indicate clickability */
}

.payment-options > * {
  margin-right: 20px; /* Adjust the spacing as needed */
}

.tooltip-right {
  position: fixed;
  border-radius: 10px;
  border: #1f6070;
  box-shadow: 2px 5px 5px black;
}

.tool-tip {
  color: #1f6070;
  font-weight: 600;
  font-size: 18px;
}

.greyed-out {
  /* Add styles to make the components appear greyed out */
  opacity: 0.5; /* You can adjust the opacity value */
  pointer-events: none; /* Disable pointer events to prevent interactions */
}

.space-after-text {
  margin-right: 91px;
}
.tooltip-trigger {
  position: relative;
}

.tooltip-trigger::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%; /* Position to the right of the button */
  transform: translate(5px, -50%); /* Adjust as needed for spacing */
}
.button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically if needed */
  padding-top: 15px;
  padding-bottom: 15px;
}
.bottom-btn-nothing {
  background-color: #bccdd8;
  color: black;
  opacity: 0.5; /* You can adjust the opacity value */
  pointer-events: none; /* Disable pointer events to prevent interactions */
}

.bottom-btn {
  background-color: #1f6070 !important;
  color: white !important;
}

.continue-btn {
  width: 80%;
  font-size: 12;
  font-weight: 600;
  border-radius: 100px;
}
</style>
