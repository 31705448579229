<template>
  <div id="container-card" class="container-page">
    <div class="pa-6" style="height: 80vh">
      <v-row v-if="loading" class="justify-center">
        <v-progress-circular color="primary" indeterminate />
      </v-row>
      <v-form @submit.prevent v-else>
        <v-combobox
          ref="select"
          v-if="messageGroups && messageGroups.length > 0"
          :loading="messageGroupsLoading"
          prepend-inner-icon="To:"
          v-model="group"
          v-bind:items="messageGroups"
          item-text="name"
          item-value="id"
          menu-props="auto"
          :disabled="loading"
          style="border-radius: 16px"
          outlined
          dense
        ></v-combobox>
        <TextField
          auto-grow
          prepend-inner-icon="Subject:"
          v-model="subject"
          outlined
          :disabled="loading"
          dense
          class="subject"
        ></TextField>

        <!-- <div class="attach row" elevation="0">
          <div class="card-title-attach">Attachment:</div>
          <v-file-input
            :disabled="loading"
            prepend-icon=""
            v-model="file"
            label=""
            style="
              border-style: none !important;
              margin-right: 10px;
              margin-top: 5px;
              height: 37px;
              margin-bottom: 23px;
            "
          />
        </div> -->
        <TextArea
          label="Message"
          :disabled="loading"
          v-model="message"
          outlined
          dense
        ></TextArea>

        <v-alert type="error" v-if="error">
          {{ error }}
        </v-alert>
      </v-form>
      <Notify
        v-if="notification.show"
        :width="containerWidth"
        type="error"
        no-title
        @close="notification.show = false"
        :message="notification.message"
      />
    </div>
    <v-footer
      height="60px"
      style="
        background-color: transparent;
        margin-bottom: 5px;
        width: 100%;
        justify-content: center;
        position: absolute;
        bottom: 0px;
      "
    >
      <Btn
        :loading="loading"
        theme="primary"
        width="90%"
        @click="createConversation"
      >
        Send Message
      </Btn>
    </v-footer>
  </div>
</template>

<script>
import { messages, auth, api, partner } from '../../sharedPlugin';
import { toUploadFormat } from '../../utils/files';
import Notify from '../../components/global/Notify';
export default {
  components: { Notify },
  props: ['isCryoMessage', 'groupId'],
  data: () => ({
    noGroup: false,
    messageGroupsLoading: false,
    error: null,
    data_model: [],
    data: {
      subject: null,
      groupId: null,
      message: null,
      patientRequestId: null,
      attachmentName: null,
      attachmentFile: null,
      createdByName: null
    },
    messageGroups: [],
    file: null,
    showAttachment: false,
    attachmentName: null,
    notification: {
      show: false,
      message: null
    },
    loading: false
  }),
  watch: {
    loading: function (val) {
      if (!val && this.messageGroups.length === 0) {
        this.noGroup = true;
      }
    }
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed(['messageTo']),
    ...partner.mapComputed(['getPartner']),
    containerWidth() {
      return document.getElementById('container-card').offsetWidth * 0.95;
    },
    subject: {
      get() {
        return this.messageTo.subject;
      },
      set(val) {
        //this.setMessageTo({ ...this.messageTo, subject: val });
        this.data.subject = val;
        this.error = null;
      }
    },
    group: {
      get() {
        if (this.groupId && this.messageGroups) {
          let _group = this.messageGroups.find((g) => g.id === this.groupId);
          if (!_group) {
            let groups = this.messageGroups;
            groups.push({ id: 234, name: 'Cryo Services Team' });
            this.$store.commit('setMessageGroups', groups);
          }

          this.setMessageTo({
            ...this.messageTo,
            groupId: this.groupId,
            groupName: 'Cryo Services Team'
          });
          return { id: 234, name: 'Cryo Services Team' };
        }
        return this.messageGroups.find((el) =>
          this.user.role === 'patient'
            ? el.id === this.messageTo.groupId
            : el.id === this.messageTo.patientId
        );
      },
      set(val) {
        this.user.role === 'patient'
          ? this.setMessageTo({ ...this.messageTo, groupId: val })
          : this.setMessageTo({ ...this.messageTo, patientId: val });

        if (this.user.role === 'patient') {
          this.data.groupId = val;
        } else {
          this.data.patientId = val;
        }

        this.error = null;
      }
    },
    message: {
      get() {
        return this.messageTo.message;
      },
      set(val) {
        let username = this.user?.fullName || null;

        if (
          this.getPartner &&
          this.getPartner.ownerName &&
          this.getPartner.name
        ) {
          let firtNameOwner = this.getPartner.ownerName.split(' ');
          let firtNamePartner = this.getPartner.name.split(' ');
          username = firtNameOwner[0] + ' on behalf of ' + firtNamePartner[0];
        }

        this.data.createdByName = username;
        this.data.message = val;

        this.error = null;
      }
    }
  },
  methods: {
    ...messages.mapMethods([
      'setMessageTo',
      'createConversationAsync',
      'getConversationsAsync'
    ]),
    async getEmailCategoriesGroups() {
      this.loading = true;

      await api.Groups.getGroups()
        .then(async (data) => {
          if (data && data.length > 0) {
            let items = [];
            data
              .filter((item) => item.active)
              .sort((a, b) => b.createdAt - a.createdAt)
              .groupBy((el) => el.emailCategoryId)
              .forEach((item) => {
                items = items.concat(item.value);
              });
            this.messageGroups = items;
          }
        })
        .catch((err) => {
          console.log('err get groups : ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async createConversation() {
      if (this.loading) {
        return false;
      }

      if (this.data.groupId === '' || !this.data.groupId) {
        this.loading = false;
        this.notification.show = true;
        this.notification.message = 'You need to select a group';
        return false;
      }

      if (this.data.subject === '' || !this.data.subject) {
        this.loading = false;
        this.notification.show = true;
        this.notification.message = 'You need to add a subject';
        return false;
      }

      if (this.data.message === '' || !this.data.message) {
        this.loading = false;
        this.notification.show = true;
        this.notification.message = 'You need to add a message';
        return false;
      }

      this.loading = true;

      if (this.file) {
        var fileN = this.file.name.split('.');
        var fileType = fileN[1];

        if (
          fileType !== 'pdf' &&
          fileType !== 'jpg' &&
          fileType !== 'jpeg' &&
          fileType !== 'png'
        ) {
          this.loading = false;
          this.notification.show = true;
          this.notification.message = 'File type not supported';
          return false;
        }
        toUploadFormat(this.file, fileType === 'pdf')
          .then((res) => {
            this.data.attachmentName = this.file.name;
            this.data.attachmentFile = res;

            var base64 = res;

            const ATTACHMENT_MAX_SIZE = 5 * 1024 * 1024; // 5MB
            var ContentLength =
              (base64.length * 3) / 4 -
              (base64.endsWith('==') ? 2 : base64.endsWith('=') ? 1 : 0);

            if (ContentLength > ATTACHMENT_MAX_SIZE) {
              this.loading = false;
              this.notification.show = true;
              this.notification.message =
                'Please check your file size. Max upload size is 5mb!';
              return false;
            }

            this.addConversation();
          })
          .then((uploadResponse) => {})
          .finally(() => (this.uploading = false));
      } else {
        this.addConversation();
      }
    },
    async addConversation() {
      const form = {
        ...this.data,
        groupId: this.data?.groupId?.id
      };

      await api.Conversations.addConversation(form)
        .then(() => {
          this.subject = '';
          this.group = '';
          this.message = '';

          this.data = {
            subject: null,
            groupId: null,
            message: null,
            patientRequestId: null,
            attachmentName: null,
            attachmentFile: null,
            createdByName: null
          };
          this.goBack();
        })
        .catch((errorMessage) => {
          this.loading = false;
          this.notification.show = true;
          this.notification.message =
            errorMessage ?? 'Oops! Something went wrong. Please try again.';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    discard() {
      this.$emit('finished');
      this.subject = '';
      this.message = '';
      this.group = null;
    }
  },
  mounted() {
    this.getEmailCategoriesGroups();
  }
};
</script>
<style scoped>
.container-page {
  background: #fff;
  height: 100%;
}
</style>

<style lang="scss">
.v-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title {
  align-self: center;
}

.v-card-actions {
  align-self: bottom;
}

.scrollCol {
  overflow: auto;
}

.v-icon.v-icon {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.subject {
  .v-input__prepend-inner {
    padding-left: 20px;
  }
  .v-text-field__slot {
    padding-left: 20px;
  }
}

.attach {
  margin-top: 0px;
  margin-bottom: 30px;
  margin-left: 0px;
  height: 38px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid gray;
  align-content: center;
  align-items: center;

  .card-title-attach {
    padding: 4px;
    padding-left: 15px;
    margin-right: 5px;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }

  .border-radius-16-attach {
    height: 38px;
  }
}

.v-file-input > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.v-file-input > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.v-select.v-select--solo {
  border-radius: 16px;
}
.v-select.v-select--solo .v-input__control {
  min-height: 46px;
}
.v-select.v-select--solo:not(.v-select--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}

.v-input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.0024em;
}
</style>
