<template>
  <v-list class="pt-0">
    <v-list-item
      v-for="(conversation, index) in getItems"
      :key="index"
      three-line
      @click="selectConversation(conversation)"
      :class="currentIndex === index && 'selected'"
      style="border-bottom: 0.5px solid #646569"
    >
      <v-list-item-content>
        <v-list-item-title class="d-flex flex-row item-title">
          <v-img
            v-if="conversation.patientUnreadCount > 0"
            src="../../assets/ball.png"
            height="10px"
            width="10px"
            max-width="10px"
            class="image-ball"
          />
          {{ conversation.groupName }}
        </v-list-item-title>
        <v-list-item-subtitle class="item-subtitle">
          {{ conversation.subject }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="message-preview">
          {{ conversation.lastMessagePreview }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text class="d-flex flex-row mt-auto">
          {{
            conversation.lastMessageDate
              ? dateFormat(conversation.lastMessageDate)
              : 'No messages'
          }}

          <v-img
            src="../../assets/right.png"
            width="8px"
            height="14px"
            class="image-arrow"
          />
        </v-list-item-action-text>

        <v-list-item-action-text v-if="conversation.lastMessageHasAttachment">
          <!-- <v-img
            src="../../assets/Paperclip.png"
            height="26px"
            width="16px"
            class="image-arrow"
          /> -->
        </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>

    <InfiniteLoading
      :identifier="infiniteLoadingKey"
      class="mt-4"
      @infinite="fetchConversations"
    >
      <div slot="spinner">
        <v-progress-circular size="18" color="primary" indeterminate />
        <p>Loading...</p>
      </div>
      <div slot="no-more">No more conversations</div>
      <div slot="no-results">No results in conversations</div>
    </InfiniteLoading>
  </v-list>
</template>
<script>
import { auth, supportChat, api, messages } from '../../sharedPlugin';
import { mapState, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';
import { unescape } from '../../utils/helpers';
require('moment-timezone');
export default {
  name: 'SupportChatConversationsList',
  props: ['styles'],
  components: {
    InfiniteLoading
  },
  data: () => ({
    moment,
    infiniteLoadingKey: Math.random(),
    currentIndex: null
  }),
  computed: {
    // Global
    ...auth.mapComputed(['user']),
    ...mapState('supportChat/conversations', {
      conversationsItems: 'items',
      conversationsPagination: 'pagination',
      conversationsLoading: 'loading'
    }),
    getItems() {
      let data = this.conversationsItems;
      data.map((item) => {
        item.lastMessagePreview = item.lastMessagePreview
          ? unescape(item.lastMessagePreview)
              .replaceAll(/<[^>]*>/g, ' ')
              .replaceAll(/&\w+/g, '')
              .trim()
          : null;
        return item;
      });
      return data;
    }
  },
  mounted() {
    this.cleanItemsConversations();
    this.conversationsPagination.page = 1;
  },
  methods: {
    // Global
    dateFormat(lastMessageDate) {
      var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
      var abbr = '(' + timeZoneAbbr + ')';

      let diff = moment().diff(lastMessageDate, 'days');

      if (diff < 1) {
        let date = moment(lastMessageDate).format('h:mma');
        return `${date} ${abbr}`;
      } else if (diff < 2) return 'Yesterday';
      else return moment(lastMessageDate).format('MM/DD/YYYY');
    },
    ...supportChat.conversations.mapMethods([
      'setSelectedConversation',
      'pushItemsConversations',
      'setPaginationConversation',
      'cleanItemsConversations'
    ]),
    ...mapActions('supportChat/conversations', {
      getConversations: 'fetchData'
    }),
    // Local
    async fetchConversations($state) {
      await this.getConversations().then((data) => {
        if (data.items.length) {
          this.setPaginationConversation({
            itemsLength: data.itemsLength,
            page: this.conversationsPagination.page + 1
          });
          this.pushItemsConversations(data.items);
          if ($state) {
            $state.loaded();
          }
        } else {
          if ($state) {
            $state.complete();
          }
        }
      });
    },
    selectConversation(conversation) {
      this.setSelectedConversation(conversation);
      this.$router.push({ path: '/messages/conversations' });
    },
    newConversation() {
      this.setSelectedConversation(null);
      this.$emit('newConversation');
    }
  },
  watch: {
    selectedConversation(conversationId) {
      if (conversationId)
        this.currentIndex = this.conversations.findIndex(
          (conversation) => conversation.id === conversationId
        );
    }
  }
};
</script>
<style scoped>
.selected {
  background-color: #9e969654;
}

.image-arrow {
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 3px;
}

.image-ball {
  margin-left: 5px;
  margin-right: 5px;
  max-height: 10px;
  margin-top: 4px;
}

.footer {
  background-color: transparent;
  margin-top: 30px;
}
</style>
<style lang="scss">
.item-title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #000 !important;
}

.item-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000 !important;
}

.message-preview {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000 !important;
}
</style>
